<template>
  <footer class="footer">
    <app-social class="footer__social" />
  </footer>
</template>

<script>
import AppSocial from './AppSocial.vue';

export default {
  name: 'AppFooter',

  components: { AppSocial },
};
</script>

<style lang="stylus" scoped>
.footer
  display flex
  justify-content center
  padding 20px
  &__social
    color #fff
</style>
