import Vue from 'vue';
import PrismicVue from '@prismicio/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import InlineSvg from 'vue-inline-svg';
import VueLazyload from 'vue-lazyload';
import App from './App.vue';
import router from './router';
import linkResolver from './link-resolver';
import store from './store';

const accessToken = 'MC5ZYVpibUJNQUFEQUF0c1Zh.BiLvv70c77-9Ae-_ve-_vXNdSBACPyox77-9QwHvv73vv70g77-977-9FiJm77-9FO-_ve-_vW0';
const endpoint = 'https://veronika-bondarchuk-portfolio.prismic.io/api/v2';

Vue.config.productionTip = false;

Vue.component('inline-svg', InlineSvg);

Vue.use(
  PrismicVue, {
    endpoint,
    apiOptions: { accessToken },
    linkResolver,
  },
);
Vue.use(VueLazyload);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
