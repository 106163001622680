<template>
  <div>
    <app-tabs :tabs="tabs" v-model="tab" />

    <section-works-items
      v-for="(item, i) of filteredWorksList"
      :title="item.title"
      :items="item.works"
      :key="i"
      :open-in-gallery="item.openInGallery"
    />
  </div>
</template>

<script>
import AppTabs from './AppTabs.vue';
import SectionWorksItems from './SectionWorksItems.vue';

export default {
  name: 'SectionWorks',

  components: {
    SectionWorksItems,
    AppTabs,
  },

  data: () => ({
    tab: 'all',
    works: [],
    categories: [],
  }),

  computed: {
    filteredWorksList() {
      return this.tab === 'all'
        ? this.worksList
        : this.worksList.filter((w) => w.category === this.tab);
    },

    worksList() {
      return this.categories.map((category) => {
        const works = this.works.filter((work) => work.categoryId === category.id);
        return {
          title: category.title,
          category: category.id,
          openInGallery: category.openInGallery,
          works,
        };
      });
    },

    tabs() {
      return [
        {
          name: 'all',
          label: 'Всі',
        },
        ...this.categories.map(({ id: name, title: label }) => ({ name, label })),
      ];
    },
  },

  methods: {
    async fetchWorks() {
      const { results } = await this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'work'), {
          pageSize: 1000,
          orderings: '[my.work.work_order desc]',
        },
      );

      this.works = results
        .map(({
          uid, data:
            { work_category: category, work_title: title, work_images: images },
        }) => ({
          id: uid,
          categoryId: category?.id,
          title: title[0]?.text,
          preview: images[0]?.work_image.url,
          images: images?.map(({
            work_image: img,
            work_image_type: type,
          }) => ({
            url: img.url,
            type,
          })),
        }));
    },

    async fetchCategories() {
      const { results } = await this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'category'), {
          orderings: '[my.category.category_order desc]',
        },
      );

      this.categories = results
        .map(({ id, data: { category_title: title, category_open_gallery: openInGallery } }) => ({
          id,
          openInGallery,
          title: title[0].text,
        }));
    },
  },

  async created() {
    await this.fetchCategories();
    await this.fetchWorks();
  },
};
</script>
