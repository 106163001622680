<template>
  <app-page class="home">
    <section-main
      class="home__section-main"
      :title="page.title"
      :description="page.description"
      :subtitle="page.subtitle"
      :button="page.button"
      :contacts="contacts"
      :avatar="page.avatar"
    />

    <app-container>
      <app-section title="Мої роботи">
        <section-works />
      </app-section>

      <app-section title="Контакти">
        <section-contacts />
      </app-section>
    </app-container>
  </app-page>
</template>

<script>
import { mapState } from 'vuex';
import SectionMain from '../components/SectionMain.vue';
import AppSection from '../components/AppSection.vue';
import SectionWorks from '../components/SectionWorks.vue';
import SectionContacts from '../components/SectionContacts.vue';
import AppContainer from '../components/AppContainer.vue';
import AppPage from '../components/AppPage.vue';

export default {
  name: 'Home',

  components: {
    AppPage,
    AppContainer,
    SectionContacts,
    SectionWorks,
    AppSection,
    SectionMain,
  },

  data: () => ({
    page: {
      title: '',
      description: [],
      subtitle: '',
      button: {
        text: '',
        link: '',
      },
    },
  }),

  computed: {
    ...mapState({
      contacts: (s) => s.contacts.list,
    }),
  },

  methods: {
    async fetchData() {
      try {
        const {
          data: {
            // eslint-disable-next-line camelcase
            page_home_title,
            // eslint-disable-next-line camelcase
            subtitle: page_home_subtitle,
            // eslint-disable-next-line camelcase
            page_home_description,
            // eslint-disable-next-line camelcase
            page_home_button,
            // eslint-disable-next-line camelcase
            page_home_avatar,
          },
        } = await this.$prismic.client.getSingle('page_home');

        this.page.title = page_home_title[0]?.text;
        // eslint-disable-next-line camelcase
        this.page.subtitle = page_home_subtitle;
        this.page.description = page_home_description.map(({ text }) => text);
        this.page.button.link = page_home_button[0]?.page_home_button_link?.url;
        this.page.button.text = page_home_button[0]?.page_home_button_text[0]?.text;
        this.page.avatar = page_home_avatar.url;
      } catch (e) {
        console.error(e);
      }
    },
  },

  created() {
    this.fetchData();
  },
};
</script>

<style lang="stylus" scoped>
.home
  padding-top 0
  &__section-main
    margin-top -80px
    margin-bottom 120px
    @media screen and (max-width 767px)
      margin-bottom 60px
</style>
