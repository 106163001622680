<template>
  <div class="section">
    <h2 class="section__title">
      {{ title }}
    </h2>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppSection',

  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus" scoped>
.section
  padding-bottom 152px
  @media screen and (max-width 767px)
    padding-bottom 48px

  &__title
    margin-bottom 60px
    font-weight bold
    font-size 30px
    line-height 40px
    text-align center
    position relative
    @media screen and (min-width 768px)
      font-size 50px
      line-height 60px
    &::after
      content: ''
      position: absolute;
      bottom -11px
      height 3px
      background: $color-accent
      display block
      left 50%
      transform translateX(-50%)
      width: 60px
</style>
