<template>
  <div
    :class="[iconOnHover && 'card--hover-icon']"
    class="card"
    :data-title="title"
  >
    <div
      class="card__img-container"
      :style="{ ...imgRatio ? { 'padding-top': `${imgRatio}%` } : {} }"
    >
      <img v-lazy="imgSrc" alt="" class="card__img" v-if="imgSrc" >
    </div>

    <div v-if="title" class="card__title">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCard',

  props: {
    title: {
      type: String,
      default: '',
    },
    imgSrc: {
      type: String,
      default: '',
    },
    imgRatio: {
      type: Number,
      default: 0,
    },
    iconOnHover: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="stylus" scoped>
.card
  box-shadow: 0 4px 20px rgba(33, 33, 33, 0.1)
  border-radius: 8px
  position relative
  overflow hidden
  cursor pointer
  height 100%
  background: #313131
  &::after
    content attr(data-title)
    position absolute
    top 0
    left 0
    right 0
    bottom  0
    background $color-accent
    transition $transition-3
    opacity 0
    display flex
    align-items center
    justify-content center
    text-transform uppercase
    text-align center
  &--hover-icon
    &::after
      content ''
      background $color-accent url('../assets/images/zoom.svg') center no-repeat
  @media screen and (min-width 768px)
    &:hover
      &::after
        opacity .9
  &__img-container
    position relative
    padding-top 70%
    overflow hidden
  &__img
    position absolute
    left 0
    top 0
    right 0
    bottom 0
    object-fit cover
    width 100%
  &__title
    padding 20px
    font-weight bold
    font-size 18px
    line-height 28px
    text-transform uppercase
    @media screen and (max-width 767px)
      font-size 16px
      line-height 26px
</style>
