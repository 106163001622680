<template>
  <div class="main-section">
    <app-container>
      <div class="main-section__wrap">
        <img class="main-section__image" :src="avatar" alt="">
        <div class="main-section__data">
          <div class="main-section__name">
            <div class="main-section__name-val">
              <span>{{ title }}</span><span v-if="title" class="main-section__dot"></span>
            </div>
          </div>
          <div class="main-section__subtitle">
            {{ subtitle }}
          </div>
          <div class="main-section__desc">
            <div v-for="(p, i) of description" :key="i">
              {{ p }}
            </div>
          </div>

          <a v-if="button.text" :href="button.link" class="main-section__button">
            {{ button.text }}
          </a>

          <section-main-contacts class="main-section__contacts" :list="contacts"/>
        </div>
      </div>
    </app-container>
  </div>
</template>

<script>
import SectionMainContacts from './SectionMainContacts.vue';
import AppContainer from './AppContainer.vue';

export default {
  name: 'SectionMain',

  components: {
    AppContainer,
    SectionMainContacts,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      subtitle: String,
      default: '',
    },
    description: {
      type: Array,
      default: () => [],
    },
    button: {
      type: Object,
      default: () => ({}),
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    avatar: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus" scoped>
.main-section
  &__wrap
    display flex
    align-items center
    justify-content space-between

  &__data
    margin-top 154px
    @media screen and (min-width 768px)
      margin-top 188px

  &__dot
    width 4px
    height 4px
    border-radius 50%
    display inline-flex
    background $color-accent
    @media screen and (min-width 768px)
      width 12px
      height 12px

  &__name
    font-size 30px
    line-height 39px
    font-weight bold
    max-width 544px
    margin-bottom 8px
    display flex
    align-items center
    @media (min-width 768px)
      margin-bottom 4px
      font-size 70px
      line-height 80px
  &__name-val
    margin-right 20px
    color #ffffff

  &__subtitle
    font-size: 24px
    line-height: 31px
    font-weight: 700
    max-width 152px
    margin-bottom: 80px
    color #ffffff
    @media (min-width 768px)
      font-size 40px
      line-height 80px
      font-weight 700
      max-width unset
      margin-bottom 32px

  &__desc
    font-weight 500
    font-size 16px
    line-height 24px
    margin-bottom 24px
    color #B7B7B7
    border-left 3px solid $color-accent
    padding-left 16px
    @media (min-width 768px)
      margin-bottom 56px
      max-width 670px
      font-size 24px
      line-height 34px

  &__button
    height 40px
    display inline-flex
    align-items center
    color #fff
    padding 0 40px
    border-radius 8px
    font-weight bold
    font-size 24px
    line-height 34px
    background-color $color-accent
    text-decoration none
    transition $transition-3
    @media (min-width 768px)
      height 66px
    &:hover
      opacity .95
    @media screen and (max-width 767px)
      font-size 18px
      line-height 28px

  &__contacts
    margin-top 24px
    @media screen and (min-width 768px)
      margin-top 54px

  &__image
    position: absolute
    right -74px
    top 79px
    z-index: -1
    width 254px
    @media (min-width 768px)
      right -98px
      top 47px
      width 965px
    @media (min-width 1920px)
      right -98px
      top 47px
</style>
