<template>
  <div class="contacts">
    <div class="contacts__item"
      v-for="{ title, linkTitle, linkValue, targetBlank } of list"
      :key="linkValue"
    >
      <div class="contacts__label">
        {{ title }}
      </div>
      <a :href="linkValue" :target="targetBlank ? '_blank' : null" class="contacts__value">
        {{ linkTitle }}
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MainSectionContacts',

  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus" scoped>
.contacts
  padding 16px 0
  margin 0 -30px
  display flex
  flex-wrap wrap
  @media screen and (max-width 767px)
    padding 0
  &__item
    padding 0 30px
    @media screen and (min-width 768px)
      &:not(:last-child)
        border-right 1px solid #7E7E7E
    @media screen and (max-width 767px)
      margin-bottom 15px

  &__label
    margin-bottom 16px
    font-size 24px
    line-height 34px
    color #B7B7B7
    @media screen and (max-width 767px)
      font-size 14px
      line-height 24px
      margin-bottom 5px
  &__value
    transition $transition-3
    white-space nowrap
    text-decoration none
    color $color-text
    cursor pointer
    &:hover
      color $color-accent
    @media screen and (max-width 767px)
      font-size 14px
      line-height 24px
</style>
