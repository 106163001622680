<template>
  <div class="gallery" v-if="value">
    <div class="gallery__wrap">
      <div class="gallery__container">
        <button class="gallery__btn gallery__btn--close gallery__close" @click="onClose" />
        <div class="swiper-container gallery__carousel" ref="carousel">
          <div class="swiper-wrapper">
            <div class="swiper-slide gallery__slide" v-for="(img, i) of images" :key="i">
              <img :src="img" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gallery__nav">
      <button class="gallery__btn gallery__btn--prev" @click="prevSlide" />
      <button class="gallery__btn gallery__btn--next" @click="nextSlide" />
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/swiper.min.css';

export default {
  name: 'AppGallery',

  props: {
    value: {
      type: Boolean,
    },
    images: {
      type: Array,
      default: () => [],
    },
    initSlide: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    carousel: null,
  }),

  watch: {
    value(v) {
      this.$nextTick(() => {
        if (v) {
          this.carousel = new Swiper(this.$refs.carousel, {
            autoHeight: true,
            initialSlide: this.initSlide,
          });
          document.body.style.overflowY = 'hidden';
          document.body.style.height = '100vh';
        }
      });
    },
  },

  methods: {
    onClose() {
      this.$emit('input', false);
      document.body.style.overflowY = null;
      document.body.style.height = null;
    },

    nextSlide() {
      this.carousel.slideNext();
    },

    prevSlide() {
      this.carousel.slidePrev();
    },
  },
};
</script>

<style lang="stylus" scoped>
.gallery
  &__carousel
    overflow: hidden
  &__wrap
    position fixed
    top 0
    right 0
    bottom 0
    left 0
    background rgba(78, 78, 81, .8)
    backdrop-filter blur(15px)
    overflow-y auto
    padding 110px 0
    &::-webkit-scrollbar
      display: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    @media screen and (max-width 767px)
      padding 60px 0
  &__container
    width 1295px
    max-width calc(100% - 30px)
    margin 0 auto
    position relative
    user-select none
  &__btn
    width 50px
    height 50px
    outline none
    background transparent center no-repeat
    border none
    cursor pointer
    border-radius 8px
    transition .3s
    &--prev
      background-image url('../assets/images/gallery/arrow-left.svg')
    &--next
      background-image url('../assets/images/gallery/arrow-right.svg')
    &--close
      background-image url('../assets/images/gallery/close.svg')
    &:hover
      background-color #646464
  &__nav
    display flex
    justify-content space-between
    width 1860px
    max-width 100%
    position fixed
    margin 0 auto
    top 50%
    left 50%
    transform translate(-50%, -50%)
    @media screen and (max-width 767px)
      display none
  &__close
    position absolute
    top -60px
    right -14px
    @media screen and (max-width 767px)
      top -50px
  &__slide
    text-align center
</style>
