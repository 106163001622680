<template>
  <header class="header" :class="{ 'header--no-bg': noBg }">
    <app-container>
      <div class="header__inner">
        <router-link to="/" class="header__logo">
          <img src="/logo.svg" alt="Veronika Bondarchuk">
        </router-link>

        <div>
          <app-social class="header__social"/>
        </div>
      </div>
    </app-container>
  </header>
</template>

<script>
import AppSocial from './AppSocial.vue';
import AppContainer from './AppContainer.vue';

export default {
  name: 'AppHeader',

  components: {
    AppContainer,
    AppSocial,
  },

  props: {
    noBg: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="stylus" scoped>
.header
  &__inner
    display flex
    align-items center
    justify-content space-between
    padding-top 40px

  &__logo
    max-width 154px
    @media screen and (min-width 768px)
      max-width unset
  &__social
    color $color-text
</style>
