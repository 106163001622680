<template>
  <div class="tabs">
    <div class="tabs__wrap">
      <div class="tabs__slider" ref="slider" />
      <div
        v-for="{label, name} of tabs"
        :class="{ 'tabs__tab--active': name === value }"
        :key="name"
        class="tabs__tab"
        @click="onTabClick(name)"
      >
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionWorksTabs',

  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.setSliderPos();
      },
    },
  },

  methods: {
    setSliderPos() {
      this.$nextTick(() => {
        const activeTabIndex = this.tabs.findIndex((t) => t.name === this.value);
        const activeTabEl = this.$refs.tabRefs[activeTabIndex];

        const sliderOffset = this.$refs.tabRefs.reduce((acc, el, i) => {
          if (i < activeTabIndex) {
            // eslint-disable-next-line no-param-reassign
            acc += el.offsetWidth + 24;
          }
          return acc;
        }, 0);

        this.$refs.slider.style.width = `${activeTabEl.offsetWidth}px`;
        this.$refs.slider.style.transform = `translateX(${sliderOffset}px)`;
      });
    },

    onTabClick(name) {
      this.$emit('input', name);
    },
  },

  beforeUpdate() {
    this.tabRefs = [];
  },
};
</script>

<style lang="stylus" scoped>
.tabs
  display flex
  justify-content center
  &__wrap
    display flex
    position relative
  &__slider
    position absolute
    height 3px
    background-color $color-accent
    width 0
    bottom -10px
    left 0
    transition .2s
  &__tab
    font-weight 600
    font-size 24px
    line-height 34px
    color $color-text-grey
    transition $transition-3
    cursor pointer
    position relative
    &:not(:last-child)
      margin-right 80px
    &:hover
      color $color-accent
    &--active
      color $color-accent
    @media screen and (max-width 767px)
      font-size 14px
      line-height 24px
</style>
