import { render, staticRenderFns } from "./SectionContactsCard.vue?vue&type=template&id=ca3f7678&scoped=true&"
import script from "./SectionContactsCard.vue?vue&type=script&lang=js&"
export * from "./SectionContactsCard.vue?vue&type=script&lang=js&"
import style0 from "./SectionContactsCard.vue?vue&type=style&index=0&id=ca3f7678&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca3f7678",
  null
  
)

export default component.exports