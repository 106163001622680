<template>
  <div class="cards-section">
    <h3 class="cards-section__title">
      {{ title }}
    </h3>

    <slot />

    <div class="grid grid--gutter-30" v-if="!$slots.default">
      <div
        class="col sc-1366-3 sc-992-4 sc-768-6"
        v-for="({ id = null, title, preview }, i) of items"
        :key="i"
      >
        <app-card
          :title="title"
          :img-src="preview"
          :img-ratio="250 / 360 * 100"
          :icon-on-hover="openInGallery"
          @click.native="onCard(id, i)"
        />
      </div>
    </div>

    <app-gallery v-model="gallery" :images="galleryImages" :init-slide="galleryInitSlide" />
  </div>
</template>

<script>
import AppCard from './AppCard.vue';
import AppGallery from './AppGallery.vue';

export default {
  name: 'SectionWorksItems',

  components: {
    AppGallery,
    AppCard,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    openInGallery: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    gallery: false,
    galleryInitSlide: 0,
    galleryImages: [],
  }),

  methods: {
    onCard(id, i) {
      if (this.openInGallery) {
        this.galleryImages = this.items.reduce((acc, el) => {
          if (el.images.length) {
            // eslint-disable-next-line no-param-reassign
            acc = [
              ...acc,
              ...el.images.map(({ url }) => url),
            ];
          }

          return acc;
        }, []);

        this.galleryInitSlide = i;
        this.gallery = true;
        return;
      }

      this.$router.push({ path: `/work/${id}` });
    },
  },
};
</script>

<style lang="stylus" scoped>
.cards-section
  padding-top 152px
  @media screen and (max-width 767px)
    padding-top 40px
  &__title
    text-align center
    font-weight bold
    font-size 40px
    line-height 50px
    margin-bottom 60px
    position relative
    &::after
      content: ''
      position: absolute;
      bottom -11px
      height 3px
      background: $color-accent
      display block
      left 50%
      transform translateX(-50%)
      width: 60px
    @media screen and (max-width 767px)
      margin-bottom 30px
      font-size 25px
      line-height 35px
</style>
