<template>
  <div class="social">
    <a href="https://www.behance.net/b7veronika7454" target="_blank" class="social__icon">
      <inline-svg :src="require('@/assets/images/social/behance.svg')" />
    </a>
    <a href="https://www.linkedin.com/in/veronika-bondarchuk-60b7111bb/" target="_blank" class="social__icon">
      <inline-svg :src="require('@/assets/images/social/linkedin.svg')" />
    </a>
    <a href="https://www.instagram.com/veronika_banakh/" target="_blank" class="social__icon">
      <inline-svg :src="require('@/assets/images/social/instagram.svg')" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'AppSocial',
};
</script>

<style lang="stylus" scoped>
.social
  display flex
  gap 16px
  @media (min-width 768px)
    gap 30px
  &__icon
    height 24px
    width 24px
    transition $transition-3
    color #ffffff
    &:hover
      color $color-accent
</style>
