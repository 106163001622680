<template>
  <div class="page">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppPage',
};
</script>

<style lang="stylus" scoped>
.page
  padding-top 80px
  min-height calc(100vh - 80px)
  @media screen and (max-width 767px)
    padding-top 40px
</style>
