<template>
  <a :href="link" class="contacts-card" :target="target">
    <div class="contacts-card__icon-wrap">
      <img :src=icon alt="">
    </div>
    <div class="contacts-card__label">
      {{ label }}
    </div>
    <div class="contacts-card__value">
      {{ value }}
    </div>
  </a>
</template>

<script>
export default {
  name: 'SectionContactsCard',

  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus" scoped>
.contacts-card
  background: #313131
  display flex
  flex-direction column
  align-items center
  padding 40px
  box-shadow 0 4px 20px rgba(33, 33, 33, 0.1)
  border-radius 8px
  color $color-text
  text-decoration none
  &:hover
    .contacts-card__value
      color $color-accent
  @media screen and (max-width 767px)
    padding 30px
  &__icon-wrap
    width 120px
    height 120px
    border-radius 50%
    border 3px solid $color-accent
    margin-bottom 30px
    display flex
    justify-content center
    align-items center
    @media screen and (max-width 767px)
      margin-bottom 20px
  &__label
    font-size 24px
    line-height 34px
    margin-bottom 20px
    color #B7B7B7
    @media screen and (max-width 767px)
      margin-bottom 10px
      font-size 16px
      line-height 26px
  &__value
    transition .3s
    @media screen and (max-width 767px)
      font-size 16px
      line-height 26px
</style>
