<template>
  <div class="grid grid--gutter-30">
    <div
      v-for="{ title, linkTitle, linkValue, targetBlank, icon, } of contacts"
      :key="linkValue"
      class="col sc-992-4"
    >
      <section-contacts-card
        :label="title"
        :value="linkTitle"
        :icon="icon"
        :link="linkValue"
        :target="targetBlank ? '_blank' : null"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SectionContactsCard from './SectionContactsCard.vue';

export default {
  name: 'SectionContacts',

  components: {
    SectionContactsCard,
  },

  computed: {
    ...mapState('contacts', {
      contacts: 'list',
    }),
  },
};
</script>
