<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppContainer',
};
</script>

<style lang="stylus" scoped>
.container
  width 100%
  padding 0 20px
  @media (min-width 1512px)
    padding 0 140px
    max-width 1512px
  max-width 1530px
  margin-left auto
  margin-right auto
</style>
