<template>
  <div id="app">
    <app-header :no-bg="isHome" />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  components: { AppFooter, AppHeader },

  computed: {
    isHome() {
      return this.$route.path === '/';
    },
  },

  methods: {
    ...mapMutations('contacts', {
      setContacts: 'setContacts',
    }),
  },

  async created() {
    const { results } = await this.$prismic.client.query(
      this.$prismic.Predicates.at('document.type', 'contact'), {
        orderings: '[my.contact.contact_order desc]',
      },
    );

    const contacts = results
      .map(({ data }) => data)
      .map((el) => ({
        icon: el.contact_icon.url,
        title: el.contact_title[0]?.text,
        linkTitle: el.contact_link_title[0]?.text,
        linkValue: el.contact_link_value[0]?.text,
        targetBlank: el.contact_link_new_tab,
      }));

    this.setContacts(contacts);
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600;700&display=swap');

html
  overflow-x hidden
body
  font-family: Raleway, sans-serif
  font-weight 500
  font-size 24px
  line-height 34px
  color $color-text
  max-width 1920px
  overflow-x hidden
  background #0F0F0F
  margin 0 auto
  position relative
*
  box-sizing border-box
  margin 0
  padding 0
img
  max-width 100%

a
  text-decoration none
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
</style>
